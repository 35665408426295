.wrapper {
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  composes: absolute from global;
}

@keyframes easeInContainer {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  animation: easeInContainer 0.3s ease-in;
  max-width: 400px;
  composes: secondaryRoman from global;
  composes: secondaryRoman-20 from global;
  composes: relative from global;
  composes: mt-0 from global;
  composes: mb-auto from global;
  composes: mx-auto from global;
  composes: p-6 from global;
  composes: bg-white from global;
  composes: border-2 from global;
  composes: border-blue-icca from global;
  composes: rounded-lg from global;
  composes: opacity-100 from global;

  @media screen and (max-width: 640px) {
    max-width: 350px;
  }
}

// Presets
a,
button,
input,
label,
span {
  .form & {
    //display: block;
    font-size: 75%;
    width: 100%;
  }
}

// Logo
.logo {
  composes: mx-auto mb-2 from global;
}

// Container
.formRow {
  composes: row from global;
  composes: secondaryRoman secondaryRoman-25 from global;
}

// Form styles
.form {
  width: 400px;
  composes: block from global;
  composes: mx-auto mb-7 px-4 from global;
  composes: text-gray-dark from global;
}

.formChild {
  composes: px-5 my-4 from global;
}

.input {
  composes: border mt-3 p-3 rounded-sm from global;
}

.inputBase {
  composes: input;
  composes: border-gray-medium from global;
}

.inputFail {
  composes: input;
  composes: border-red from global;
}

.inputPass {
  composes: input;
  composes: border-al-dia-green from global;
}

.button {
  composes: btn btn-icca from global;
  composes: my-6 rounded-lg from global;

  &:disabled {
    opacity: 0.33;
  }
}

// Alert styles
.alert {
  visibility: visible;
  opacity: 0.67;
  animation: alert-sd 0.5s ease-in;
  composes: secondaryRoman secondaryRoman-10 from global;
  composes: block rounded-lg from global;
  composes: p-5 bg-alert text-white from global;
}

@keyframes alert-sd {
  from {
    visibility: hidden;
    opacity: 0;
    height: 0;
  }

  to {
    visibility: visible;
    opacity: 0.67;
    height: 100%;
  }
}

// Error styles
.errorShow {
  visibility: visible;
  opacity: 0.8;
  animation: error-sd 0.5s ease-in;
  composes: secondaryRoman secondaryRoman-10 from global;
  composes: block rounded-sm from global;
  composes: px-3 py-2 from global;
  composes: bg-alert text-white from global;
}

@keyframes error-sd {
  from {
    visibility: hidden;
    opacity: 0;
    height: 0;
  }

  to {
    visibility: visible;
    opacity: 0.8;
    height: 100%;
  }
}
