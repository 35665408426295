.wrapper {
  //composes: secondaryRoman secondaryRoman-25 from global;
  composes: row expanded bg-gray-light from global;
  composes: min-h-screen from global;
}

.caret {
  composes: absolute from global;
  composes: top-0 from global;
  composes: left-2 from global;
  composes: right-0 from global;
  composes: w-0 from global;
  composes: h-0 from global;
  margin-right: -40px;
  border-top: 1.375rem solid transparent;
  border-left: 2.5rem solid #0074c1;
  border-bottom: 1.375rem solid transparent;
}