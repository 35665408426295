.wrapper {
  composes: large-12 from global;
  composes: medium-12 from global;
  composes: primaryBold from global;
  composes: primaryBold-30 from global;
  composes: text-blue-icca from global;
}

.container {
  composes: row from global;
  composes: expanded from global;
  composes: align-justify from global;
}

.hero {
  background: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.5),
      rgba(255, 255, 255, 0.75)
    ),
    url(../../resources/images/icca-2015-cropped.png) no-repeat;
  background-size: 100%;

  @media (min-width: 768px) {
    background: linear-gradient(
        to bottom,
        rgba(245, 246, 252, 0.5),
        rgba(255, 255, 255, 0.65)
      ),
      url(../../resources/images/icca-2015-cropped.png) no-repeat;
    background-size: cover;
  }
}

.leader {
  composes: ml-3 from global;
  composes: py-4 from global;
}

.social {
  composes: flex from global;
  composes: flex-row from global;
  composes: justify-between from global;
  composes: content-between from global;
  composes: ml-auto mt-6 mr-6 from global;
}

.icon {
  composes: mx-2 from global;

  &:hover {
    opacity: 0.75;
  }
}

.nav {
  composes: secondaryBold from global;
  composes: secondaryBold-10 from global;
  composes: bg-blue-icca from global;
  composes: text-white from global;
  composes: border-t-2 from global;
  composes: border-b-2 from global;
  composes: border-blue-dark from global;
}

.navbar {
  composes: flex from global;
  composes: flex-auto from global;
  composes: flex-no-wrap from global;
  composes: justify-around from global;
  composes: mx-auto from global;
}

.navlink {
  composes: py-4 from global;
}

.navlink:hover,
.active {
  color: #101010;
}
