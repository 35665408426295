html {
  background-color: #e7e7e7;
  display: table;
  width: 100%;
  height: 100%;
}

body {
  display: table-cell;
  vertical-align: middle;
  margin: 2em auto;
}

.container {
  composes: text-center from global;
  composes: text-blue-dark from global;
  composes: opacity-50 from global;
  composes: leading-tight from global;
  composes: mt-8 mx-auto from global;
}

.logo {
  composes: inline-block from global;
  composes: mt-4 mx-auto from global;
}

.header {
  composes: text-6xl from global;
}

.content {
  composes: text-xs from global;
  composes: p-4 from global;
}
